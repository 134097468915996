<template>
  <div class="container">
    <div class="pa-20 fixed-box">
      <div class="flex f-center f-between">
        <img class="logo" src="~@/assets/img/logo.png" />
        <div class="flex f-center back-box" @click="$router.replace('/')">
          <img class="back" src="~@/assets/img/back.png" />
          <span>返回</span>
        </div>
      </div>
      <div class="flex f-between ma-t-10">
        <div class="box">
          <div class="title">
            <span class="font-bold">预约信息（多人预约）</span>
            <span class="more" @click="show = true">查看更多》</span>
          </div>
          <div class="content">
            <div class="flex f-end width50">
              <div class="width60">
                <div>公司：{{ info.company }}</div>
                <div class="ma-t-10">姓名：{{ info.name }}</div>
                <div class="ma-t-10">
                  来访类型：{{ $util.getVisitType(info.visitType) }}
                </div>
                <div class="ma-t-10">车牌号：{{ fullCarNo }}</div>
              </div>
            </div>
            <div class="flex f-end width50">
              <div class="width60">
                <div class="t-omit-1" @click="show = true">来访理由：{{ info.reason }}</div>
                <div class="ma-tb-10">受访人：{{ info.intvName }}</div>
                <div class="ma-tb-10">
                  受访人座机/手机：{{ info.intvPhone }}
                </div>
                <div>访问时间：{{ info.visitDate }} {{info.visitEndDate? '~ '+info.visitEndDate : '' }}</div>
                <div class="ma-t-10"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box3">
        <div class="t-center title">
          <van-button
            plain
            type="primary"
            class="btn-additem"
            @click="showForm = true"
            >添加</van-button
          >
          确认信息
        </div>
        <div class="form-big">
          <div class="form">
            <div
              class="tab"
              v-for="(item, index) in members"
              :key="index"
              :class="{
                active:
                  checkList.findIndex((t) => t.id == item.id) != -1 &&
                  item.inPark == 0,
              }"
            >
              <div class="name" @click="check(item)">
                <div>
                  人员{{ $util.toChinesNum(index + 1) }}：{{ item.name }}
                </div>
                <div
                  class="btn-delitem"
                  @click.stop="delItem(item)"
                  v-if="item.isShowDel !== true"
                >
                  <van-icon
                    :color="
                      checkList.findIndex((t) => t.id == item.id) != -1
                        ? 'white'
                        : 'black'
                    "
                    name="close"
                    size="20"
                  />
                </div>
              </div>
              <!-- <div class="flex f-center f-between pa-lr-10 ma-tb-10">
                <div class="width30 t-right">
                  <span class="star">*</span>体温
                </div>
                <el-select v-model="item.temperature" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div> -->
              <div class="flex f-center pa-lr-10 ma-t-20">
                <div class="width40 t-right">
                  <span class="star">*</span>访客证号码：
                </div>
                <van-field
                  v-if="item.isShowDel !== true"
                  class="input"
                  v-model="item.cardNo"
                  label=""
                />
                <div v-else class="width70 t-left">{{ item.cardNo }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex f-between pa-lr-10 ma-b-30 ma-t-20">
          <div class="flex f-center fon-16">
            <div>访客停车证号码：</div>
            <div class="inputs flex f-center">
              <el-tag
                v-for="(tag, index) in info.parkingNoList"
                :key="tag"
                closable
                @close="closeTag(index)"
                class="ma-r-10"
                size="large"
              >
                {{ tag }}
              </el-tag>
              <van-field
                class="inputss"
                v-model="parkingNo"
                label=""
                size="large"
                @blur="blur"
              />
            </div>
          </div>
          <van-checkbox v-model="intvConfirm" shape="square" class="fon-16"
            >受访人已经同意入内</van-checkbox
          >
        </div>
        <div class="width30 ma-lr-auto">
          <van-button
            type="primary"
            size="normal"
            class="width100"
            color="#003AA9"
            @click="submit"
            >确认</van-button
          >
        </div>
      </div>
    </div>
    <van-overlay :show="show">
      <div class="overlays">
        <div class="overlay-box ma-b-10">
          <div>公司：{{ info.company }}</div>
          <div class="ma-tb-10">姓名：{{ info.name }}</div>
          <div class="ma-tb-10">手机号：{{ info.phone }}</div>
          <div class="ma-tb-10">受访人：{{ info.intvName }}</div>
          <div class="ma-tb-10">受访人部门：{{ info.intvDepartment }}</div>
          <div class="ma-tb-10">受访人座机/手机：{{ info.intvPhone }}</div>
          <div class="ma-tb-10">车牌号：{{ fullCarNo }}</div>
          <div class="ma-tb-10">访问时间：{{ info.visitDate }} {{info.visitEndDate? '~ '+info.visitEndDate : '' }}</div>
          <div class="ma-tb-10">
            来访类型：{{ $util.getVisitType(info.visitType) }}
          </div>
          <div>来访理由：{{ info.reason }}</div>
        </div>
        <van-icon color="#fff" @click="show = false" name="close" size="30" />
      </div>
    </van-overlay>

    <!-- 弹出层 -->
    <van-popup v-model:show="showForm">
      <div class="popup-item pa-30 bg-fff fon-20">
        <div class="flex f-center f-between pa-lr-10">
          <div class="width30 t-right pa-r-10">
            <span class="star">*</span>姓名
          </div>
          <van-field class="input" v-model="popupItem.name" label="" />
        </div>
        <!-- <div class="flex f-center f-between pa-lr-10 ma-tb-20">
          <div class="width30 t-right pa-r-10">
            <span class="star">*</span>体温
          </div>
          <el-select v-model="popupItem.temperature" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="flex f-center f-between pa-lr-10 ma-t-20">
          <div class="width30 t-right pa-r-10">
            <span class="star">*</span>访客证号码
          </div>
          <van-field class="input" v-model="popupItem.cardNo" label="" />
        </div>
      </div>
      <div class="flex f-around">
        <van-button
          size="normal"
          class="width100"
          plain
          @click="showForm = false"
          >取消</van-button
        >
        <van-button
          type="primary"
          size="normal"
          class="width100"
          color="#003AA9"
          @click="addItem"
          >确认</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ElMessageBox } from "element-plus";
let _this;
export default {
  data() {
    return {
      showForm: false, //是否弹出新增人员弹框
      show: false,
      popupItem: {
        inPark: "0",
        temperature: "0",
      },
      info: {
        epidInfo: {},
      },
      options: [
        {
          value: "0",
          label: "正常",
        },
        {
          value: "1",
          label: "不正常",
        },
      ],
      form: {
        id: "",
        parkingNoList: [],
      },
      members: [],
      parkingNo: "", //车牌号
      intvConfirm: false, //受访人意见
      checkList: [],
      removeIds: [], //删除的人员id
      cardNo: "", //访客证号
      cardType: "",
      code: "",
    };
  },
  created() {
    _this = this;
    _this.code = _this.$route.query.code;
    _this.cardNo = _this.$route.query.cardNo;
    _this.cardType = _this.$route.query.cardType; //cardType:_this.cardType
    _this.loadData();
  },
  watch: {
    $route(to, from) {
      //  window.location.reload(); //监测到路由发生跳转时刷新一次页面
    },
  },
  //监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    //next方法传true或者不传为默认历史返回，传false为不执行历史回退
    clearInterval(_this.setInterval);
    next();
  },
  computed:{
    //完整的车牌号
    fullCarNo(){
      if(_this.info.plateNoExt && _this.info.plateNoExt.length>0){
        return _this.info.plateNo+','+_this.info.plateNoExt.join(',')
      }else{
        return _this.info.plateNo
      }
    }
  },
  methods: {
    ...mapActions(["SET_CODE"]),
    // 选择
    check(item) {
      //如果已经入场，就不允许再次选择
      if (item.inPark != 0) {
        let state = "";
        if (item.inPark == 1) {
          state = "已入场";
        } else if (item.inPark == 2) {
          state = "已离厂";
        }
        return _this.$toast("无法选择，当前人员" + state);
      }

      let sid = _this.checkList.findIndex((t) => t.id == item.id);
      if (sid === -1) {
        _this.checkList.push(item);
      } else {
        _this.checkList.splice(sid, 1);
      }
    },
    //删除
    delItem(item) {
      //如果已经入场，就不允许删除
      if (item.inPark != 0) {
        let state = "";
        if (item.inPark == 1) {
          state = "已入场";
        } else if (item.inPark == 2) {
          state = "已离厂";
        }
        return _this.$toast("无法删除，当前人员" + state);
      }

      ElMessageBox.confirm("是否确认删除", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        buttonSize: 'large',
      })
        .then(() => {
          //如果删除的添加的则不需要加入到删除
          if (typeof item.id != "symbol") {
            // _this.removeIds.push(item.id)
            _this.$request({
                url: "/security/remove",
                method: "POST",
                params: {
                  id: item.id,
                },
              })
              .then(() => {
                //页面删除
                let cid = _this.checkList.findIndex((t) => t.id == item.id);
                let mid = _this.members.findIndex((t) => t.id == item.id);
                if (cid != -1) {
                  _this.checkList.splice(cid, 1);
                }
                _this.members.splice(mid, 1);
                _this.$toast("已删除");
              });
          }
        })
        .catch(() => {});
    },
    //确认添加人员
    addItem() {
      if (!_this.popupItem.name) {
        return _this.$toast("请输入姓名");
      }
      if (!_this.popupItem.temperature) {
        return _this.$toast("请选择体温");
      }
      if (!_this.popupItem.cardNo) {
        return _this.$toast("请输入访客证号码");
      }
      _this.popupItem.id = Symbol("id"); //临时添加人员赋予唯一id
      _this.members.push(_this.popupItem);
      _this.checkList.push(_this.popupItem);
      _this.popupItem = {
        inPark: "0",
        temperature: "0",
      };
      _this.showForm = false;
    },
    // 目前身体状况
    physicalCondition(val) {
      switch (val) {
        case 0:
          return "无不适";
        case 1:
          return "有发烧、咳嗽、流涕等不适症状";
      }
    },
    // 随申码
    shanghaiCode(val) {
      switch (val) {
        case 0:
          return "绿色";
        case 1:
          return "黄色";
        case 2:
          return "红色";
      }
    },
    // 来访类型
    visitType(val) {
      switch (val) {
        case 0:
          return "普通访问";
        case 1:
          return "提货";
        case 2:
          return "送货";
      }
    },

    // 加载数据
    loadData() {
      _this
        .$request({
          url: "/security/query",
          method: "GET",
          params: {
            code: _this.code,
            cardNo: _this.cardNo,
            cardType: _this.cardType,
            queryType: 0,
          },
        })
        .then((res) => {
          _this.info = res;
          _this.info.members.map((item) => {
            item.cardNo=item.cardReturned==0?item.cardNo:''//如果访客证未归还，则回显目前的访客证
            let data = {
              id: item.id,
              status: item.status,
              cardNo: item.cardNo,
              name: item.name,
              inPark: item.inPark,
              temperature: "0",
              isShowDel: Boolean(item.cardNo), //已经绑定访客证的人员入场页面不展示删除按钮，
            };
            _this.members.push(data);
            _this.checkList.push(data);
          });
          _this.info.parkingNoList = [];

          
        });
    },

    // 输入框失去焦点
    blur() {
      if (_this.parkingNo) {
        _this.info.parkingNoList.push(_this.parkingNo);
        _this.parkingNo = "";
      }
    },

    // 删除车牌号
    closeTag(index) {
      _this.info.parkingNoList.splice(index, 1);
      console.log(_this.info.parkingNoList);
    },

    // 确认
    submit() {
      let temperatureList = [],
        cardNoList = [];
      _this.checkList.map((item) => {
        temperatureList.push(item.temperature);
        cardNoList.push(item.cardNo);
      });
      if (temperatureList.indexOf("") > -1) {
        return _this.$toast("请选择体温");
      }
      if (cardNoList.indexOf("") > -1) {
        return _this.$toast("请输入访客证号码");
      }
      if (_this.$validator.isEmpty(_this.$store.state.entranceId)) {
        return _this.$dialog
          .alert({
            title: "提示",
            message: "请先绑定通道",
          })
          .then(() => {
            _this.$router.push("/setting");
          });
      }
      if (!_this.intvConfirm) {
        return _this.$toast("请勾选受访人是否同意入内");
      }

      //如果是新增的，删除id属性
      let checkList = _this.checkList.map((item) => {
        if (typeof item.id == "symbol") {
          delete item.id;
          return item;
        } else {
          return item;
        }
      });

      let form = {
        id: _this.info.id,
        intvConfirm: _this.intvConfirm ? 1 : 0,
        entranceId: _this.$store.state.entranceId,
        members: checkList,
        parkingNoList: _this.info.parkingNoList,
        removeIds: _this.removeIds,
      };
      console.log(form);
      // return

      _this
        .$request({
          url: "/security/in",
          method: "POST",
          data: form,
        })
        .then(() => {
          _this.$router.replace("/enter/success");
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/style.less";

.overlays {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .overlay-box {
    padding: 20px 40px;
    border-radius: 5px;
    background: #ffffff;
    text-align: left;
    font-size: 20px;
  }
}

.box3 {
  .title {
    position: relative;
    .btn-additem {
      position: absolute;
      left: 10px;
      top: 15px;
    }
  }
}

.popup-item {
  width: 400px;
  .star {
    color: red;
  }
  .input {
    background: #eeeeee;
    border-radius: 5px;
    padding: 5px 8px;
    width: 70%;
  }
  .el-select {
    width: 70%;
  }
  /deep/.el-input__inner {
    background-color: #eeeeee !important;
    border: none;
    height: 34px;
  }
}

.form-big {
  .tab {
    border: 1px solid #eeeeee !important;

    .name {
      background: #eeeeee !important;
      position: relative;

      .btn-delitem {
        color: red !important;
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
      }
    }
  }

  .active {
    .name {
      background: #003aa9 !important;
    }
  }

  .active.tab {
    border: 1px solid #003aa9 !important;
  }
}
/deep/.el-tag{
  height: 40px;
  line-height: 40px;
}
/deep/.el-tag .el-icon-close{
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 18px;
}
</style>
